import {useState} from "react"
import requestData from "../../../../app/utils/requestData"
import {OrderControlService} from "../../../../app/services/OrderControlService"
import {Alert} from "../../../../app/utils/alert"

export enum KaspiCancelReason {
  BUYER_CANCELLATION_BY_MERCHANT = "BUYER_CANCELLATION_BY_MERCHANT",
  BUYER_NOT_REACHABLE = "BUYER_NOT_REACHABLE",
  MERCHANT_OUT_OF_STOCK = "MERCHANT_OUT_OF_STOCK",
}

const options = [
  {label: "Отказ покупателя", value: KaspiCancelReason.BUYER_CANCELLATION_BY_MERCHANT},
  {label: "Не удалось связаться с покупателем", value: KaspiCancelReason.BUYER_NOT_REACHABLE},
  {label: "Товара нет в наличии", value: KaspiCancelReason.MERCHANT_OUT_OF_STOCK},
]

export interface OrderCancelData {
  cancellation_comment?: string
  cancellation_reason: KaspiCancelReason | string
  cancel_kaspi: boolean
}

const OrderCancelInitialData = {
  cancellation_comment: "",
  cancellation_reason: "",
  cancel_kaspi: true,
}

const useCancelKaspiOrder = () => {
  const [orderCancelData, setOrderCancelData] = useState<OrderCancelData>(OrderCancelInitialData)
  const [loading, setLoading] = useState<boolean>(false)

  const cancelOrder = async (orderSession: string, refresh: () => void) => {
    setLoading(true)
    const cancelOrderRequest = await requestData(OrderControlService.cancelOrder, orderSession, orderCancelData)
    setLoading(false)
    if (cancelOrderRequest) {
      Alert.displaySuccessMessage("Заказ был успешно отменен!")
      refresh()
    }
  }

  const changeData = (values: Partial<OrderCancelData>) => {
    setOrderCancelData((prevState) => ({...prevState, ...values}))
  }

  return {
    options,
    orderCancelData,
    changeData,
    cancelOrder,
    loading,
  }
}

export default useCancelKaspiOrder
