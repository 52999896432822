import http from "../utils/http"
import {PromiseResponse} from "../interfaces/http"
import {OrderExchange} from "../interfaces/orderExchangeInterfaces"

export namespace OrderExchangeService {
  const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/order-exchange/api/v1`

  export const getListOfExchanges = (page: number, size: number, filterData?: object) => {
    return http.get(`${BASE_URL}/order-exchange/items`, {params: {page, size, ...filterData}})
  }

  export const getOrderExchangeDetails = (orderExchangeId: number): PromiseResponse<OrderExchange> => {
    return http.get(`${BASE_URL}/order-exchange`, {params: {order_exchange_id: orderExchangeId}})
  }

  export const acceptExchange = (orderExchangeId: number) => {
    return http.post(`${BASE_URL}/order-exchange/accept`, null, {
      params: {order_exchange_id: orderExchangeId},
    })
  }

  export const takeInWorkExchange = (orderExchangeId: number) => {
    return http.post(`${BASE_URL}/order-exchange/take-to-work`, null, {
      params: {order_exchange_id: orderExchangeId},
    })
  }

  export const declineExchange = (orderExchangeId: number, comment: string) => {
    return http.post(`${BASE_URL}/order-exchange/decline`, {
      order_exchange_id: orderExchangeId,
      comment: comment,
    })
  }

  export const checkExchangeInOrder = (order_session: string) => {
    return http.get(`${BASE_URL}/order/check-exchanged`, {
      params: {order_session},
    })
  }
}
