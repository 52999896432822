import {AxiosResponse} from "axios"
import {Alert} from "./alert"
import {MimeTypesToExtensions} from "../interfaces/layoutInterfaces"

export default function downloadArrayBuffer(
  serviceFunc: (...params: any) => Promise<AxiosResponse<ArrayBuffer>>,
  templateName?: string,
  ...params: any
) {
  serviceFunc(...params)
    .then((res) => {
      const str = new TextDecoder().decode(res.data)
      try {
        const bufferCheck = JSON.parse(str)
        if (bufferCheck && bufferCheck.status === false) {
          Alert.displayErrorMessage("Не удалось выгрузить файл")
          return
        }
      } catch (e) {}
      let fileName = templateName ?? "file"
      if (!hasFileExtension(fileName)) {
        const contentType = res.headers["content-type"]
        const extension = getExtensionFromMime(contentType as keyof typeof MimeTypesToExtensions)
        if (extension) fileName += extension
        else fileName += ".xlsx"
      }
      createURLAndDownload(res, fileName)
    })
    .catch((e) => {
      console.log(e)
      Alert.displayErrorMessage("Не удалось выгрузить файл!")
    })
}

export const createURLAndDownload = (res: AxiosResponse<any, any>, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([res.data], {
      type: res.data.type,
    }),
  )
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

export const getExtensionFromMime = (mime: keyof typeof MimeTypesToExtensions) => {
  return MimeTypesToExtensions[mime]
}

export const hasFileExtension = (filename: string) => {
  const lastPeriodIndex = filename.lastIndexOf(".")
  if (lastPeriodIndex === -1) {
    return false
  }
  const extension = filename.slice(lastPeriodIndex + 1)
  return extension.length > 0
}

