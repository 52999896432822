import {useState} from "react"
import {OrderExchangeService} from "../../../../app/services/OrderExchangeService"
import requestData from "../../../../app/utils/requestData"

export interface CheckExchangeInOrderResponse {
  parent_order_session: string | null
  created_order_session: string | null
  created_order_id: number | null
  parent_order_id: number | null
}

const useExchangeMainInfo = (orderSession: string) => {
  const [parentOrderId, setParentOrderId] = useState<number | null>(null)
  const [newOrderId, setNewOrderId] = useState<number | null>(null)

  const checkExchangeInOrder = async () => {
    const response = await requestData<CheckExchangeInOrderResponse>(
      OrderExchangeService.checkExchangeInOrder,
      orderSession,
    )
    if (response) {
      setParentOrderId(response.parent_order_id)
      setNewOrderId(response.created_order_id)
    }
  }

  return {
    checkExchangeInOrder,
    parentOrderId,
    newOrderId,
  }
}

export default useExchangeMainInfo
