import React, {useEffect, useState} from "react"
import s from "./OrderByExchangeNotification.module.scss"
import useExchangeMainInfo from "../hooks/useExchangeMainInfo"

const OrderByExchangeNotification: React.FC<{orderSession: string}> = ({orderSession}) => {
  const {checkExchangeInOrder, parentOrderId, newOrderId} = useExchangeMainInfo(orderSession)

  useEffect(() => {
    void checkExchangeInOrder()
  }, [])

  if (!newOrderId && !parentOrderId) return null

  return (
    <div className={s.container}>
      {parentOrderId && <NotificationItem to="parent" orderId={parentOrderId} />}
      {newOrderId && <NotificationItem to="new" orderId={newOrderId} />}
    </div>
  )
}

type NotNotificationItemProps = {
  orderId: number
  to: "parent" | "new"
}

export const NotificationItem: React.FC<NotNotificationItemProps> = ({orderId, to}) => {
  return (
    <div className={s.wrapper}>
      <h3>{to === "parent" ? "Заказ был создан по заявке на обмен" : "По данному заказу был создан обмен"}</h3>
      <a href={`/orders/${orderId}`} target="_blank" rel="noreferrer">
        {to === "parent" ? "Ссылка на заказ с которого был создан обмен" : "Ссылка на новый заказ по обмену"}
      </a>
    </div>
  )
}

export default OrderByExchangeNotification
