import React from "react"
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material"
import useCancelKaspiOrder from "./useCancelKaspiOrder"
import Modal from "../../../../app/components/Modal/Modal"
import Button from "../../../../app/components/Button/Button"

type Props = {
  open: boolean
  onClose: () => void
  orderSession: string
  refreshService: () => void
}

const KaspiOrderCancelModal: React.FC<Props> = ({open, onClose, refreshService, orderSession}) => {
  const {orderCancelData, options, changeData, cancelOrder, loading} = useCancelKaspiOrder()
  const handleCancellation = async () => {
    await cancelOrder(orderSession, refreshService)
  }

  return (
    <Modal title="Отмена каспи заказа" open={open} handleClose={onClose} withCloseIcon hideDialogActions>
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <FormControl fullWidth variant="filled" required>
          <InputLabel id="kaspiCancelResaonSelectLabel">Выберите причину</InputLabel>
          <Select
            value={orderCancelData.cancellation_reason}
            labelId="kaspiCancelResaonSelectLabel"
            variant="filled"
            label="Выберите причину"
            onChange={(event) => changeData({cancellation_reason: event.target.value})}
            fullWidth
          >
            {options.map((reason) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={orderCancelData.cancellation_comment}
          onChange={(e) => {
            if (e.target.value.length > 500) return
            changeData({cancellation_comment: e.target.value})
          }}
          multiline
          rows={4}
          label="Комментарий"
          fullWidth
          variant="filled"
        />
        <Button onClick={handleCancellation} disabled={!orderCancelData.cancellation_reason || loading}>
          Отменить каспи заказ
        </Button>
      </Box>
    </Modal>
  )
}

export default KaspiOrderCancelModal
